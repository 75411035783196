import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Heading,
  theme,
  Icon,
  Container,
  extendTheme,
  Divider,
  Center
} from '@chakra-ui/react';
import { FaLink } from 'react-icons/fa';

const customTheme = extendTheme({
  styles: {
    global: {
      body: {
        bgGradient: 'linear(to-br, #DAE2F8, #61dafb)',
        color: 'black',
      }
    }
  }
});

function App() {
  return (
    <ChakraProvider theme={customTheme}>
      <Container maxW="container.md" py={10}>
        <VStack spacing={10}>
          <Heading as="h1" size="2xl" textAlign="center">
          👨🏼‍💻 My Portfolio
          </Heading>
          <Text fontSize="xl" textAlign="center">
          Designed for potential employers, this portfolio showcases my working style and vision. 
          Explore my projects to see my unique contributions. 💼


          </Text>
          <Box p={8} bg="whiteAlpha.800" borderRadius="lg" boxShadow="2xl">
            <Project
              name="Agency Template"
              description=" I designed this template specifically for agency-type companies. It includes a wide array of reusable React components to streamline development and enhance functionality."
              link="https://portfolio1.gulisashvili.com/"
            />
            <Project
              name="NFT Template"
              description="This template is designed to facilitate the buying and selling of NFTs. It features a comprehensive set of components, each fully functional to enhance user interface interactions and streamline transactions."
              link="https://portfolio2.gulisashvili.com/"
            />
            <Project
              name="E-commerce Template"
              description="This React template is crafted with full UI functionality, offering multiple homepage options and an extensive range of components. It's designed to enhance online shopping experiences."
              link="https://portfolio3.gulisashvili.com/"
            />
          </Box>
          <Center w="full" py={5}>
            <Text fontSize="md" textAlign="center" color="gray.600">
            © 2024 Giorgi Gulisashvili. All rights reserved. Contact me at giorgi@gulisashvili.com.
            </Text>
          </Center>
        </VStack>
      </Container>
    </ChakraProvider>
  );
}

const Project = ({ name, description, link }) => {
  return (
    <VStack align="start" p={5} shadow="lg" bg="white" borderRadius="md" mb={2} spacing={4}>
      <Heading fontSize="lg" color="gray.800">{name}</Heading>
      <Text color="gray.600">{description}</Text>
      <Link href={link} isExternal>
        <Icon as={FaLink} mr={2} color="blue.500" />
        View Project
      </Link>
    </VStack>
  );
};

export default App;
